import React from 'react';
import { useEffect, useState } from 'react';
import { FaArrowRight, FaEnvelope, FaPhone } from 'react-icons/fa';
import { FaBuilding, FaIndustry, FaDatabase } from 'react-icons/fa';
import { FaShieldAlt, FaUserCheck, FaCogs } from 'react-icons/fa';
import CaseStudySection from './CaseStudySection'; // Add this import
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import '../i18n'; // Import the i18n configuration
import BelowHeaderBanner from './BelowHeaderBanner'
import '../App.css';
import '../Header.css';
import FAQ from './FAQ';
import AboutUs from './AboutUs';
import DemoViewer from './ArcadeEmbed';
import ScrollingStats from './ScrollingStats';
import Button from '@mui/material/Button';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KnowYour from './KnowYour';
import InteractiveFlowchart from './InteractiveFlowchart';
import Integrations from './Integrations';

function HomePage() {

  //const testimonials = [
  //{ text: "Right away I knew Social-Engineer, LLC needed to partner with them to bring cutting edge services to our space.", company: "CH, CEO, Social-Engineer, LLC" },
  //{ text: "Validia offers a vital solution for protecting communications.", company: "CISO, Foreign Embassy" },
  //{ text: "The need for a KYE/KYC solution like Validia is imperative for businesses in 2024 and beyond.", company: "CRO, Data removal company & enterprises & UHNW" },
  //];


  const { t } = useTranslation();

  // New function for lazy loading images
  const lazyLoad = (target) => {
    const io = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const img = entry.target;
          const src = img.getAttribute('data-src');
          img.setAttribute('src', src);
          img.classList.add('fade');
          observer.disconnect();
        }
      });
    });
    io.observe(target);
  };

  // Use effect for lazy loading
  useEffect(() => {
    const images = document.querySelectorAll('[data-src]');
    images.forEach(lazyLoad);
  }, []);

  // const [menuOpen, setMenuOpen] = useState(false);
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  //
  // const toggleMenu = () => {
  //   setMenuOpen(!menuOpen);
  // };

  const [activeTestimonial, setActiveTestimonial] = useState('government');

  const testimonials = {
    government: {
      title: t('governmentTitle'),
      icon: <FaBuilding />,
      text: t('governmentTestimonial'),
      author: t('governmentAuthor')
    },
    dataRecovery: {
      title: t('corporateSecurityTitle'),
      icon: <FaDatabase />,
      text: t('corporateSecurityTestimonial'),
      author: t('corporateSecurityAuthor')
    },
    industry: {
      title: t('internetMonitoringTitle'),
      icon: <FaIndustry />,
      text: t('internetMonitoringTestimonial'),
      author: t('internetMonitoringAuthor')
    }
  };


  const [activeProduct, setActiveProduct] = useState('know-your-employee');
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const productInfo = {
    'know-your-employee': {
      title: t('kyeTitle'),
      tagline: t('kyeTagline'),
      description: t('kyeDescription'),
      details: [
        t('kyeDetail1'),
        t('kyeDetail2'),
        t('kyeDetail3'),
        t('kyeDetail4')
      ],
      slides: [
        {
          image: 'product/kye1.png',
          description: t('kyeSlide1')
        },
        {
          image: 'product/kye2.png',
          description: t('kyeSlide2')
        },
        {
          image: 'product/kye3.png',
          description: t('kyeSlide3')
        },
        {
          image: 'product/kye4.png',
          description: t('kyeSlide4')
        }
      ]
    },
    'jam': {
      title: t('jamTitle'),
      tagline: t('jamTagline'),
      description: t('jamDescription'),
      details: [
        t('jamDetail1'),
        t('jamDetail2'),
        t('jamDetail3'),
        t('jamDetail4'),
        t('jamDetail5')
      ],
      slides: [
        {
          image: 'product/jam1.png',
          description: t('jamSlide1')
        },
        {
          image: 'product/jam2.png',
          description: t('jamSlide2')
        },
        {
          image: 'product/jam3.png',
          description: t('jamSlide3')
        }
      ]
    },
  };

  const handlePrevImage = () => {
    setCurrentImageIndex((prevIndex) => Math.max(0, prevIndex - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      Math.min(prevIndex + 1, productInfo[activeProduct].slides.length - 1)
    );
  };

  const integrationComponent = (
    <div className="integration-logos">
      <img alt="Zoom logo" src={"zoom.png"} style={{ maxHeight: "50px"/*width: '200px', height: '100px'*/ }} />
      <img alt="Microsoft Teams logo" src={"teams.png"} style={{ maxHeight: "50px", paddingRight: '30px'/*width: '150px', height: '100px', paddingRight: '10px' */ }} />
      <img alt="Cisco Webex logo" src={"webex.png"} style={{ maxHeight: "40px", paddingRight: '30px' }} />
      <img alt="Slack logo" src={"slack.png"} style={{ maxHeight: '50px', paddingRight: '10px' }} />
      <img alt="Google Meet logo" src={"meet.png"} style={{ maxHeight: '50px', marginTop: "5px" }} />
    </div>);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };


  return (
    <div className="App">
      <Helmet>
        <title>Validia - {t('welcome')}</title>
        <meta name="description" content="Validia protects against deepfakes and AI impersonation, securing enterprise digital identity in the age of adversarial AI." />
        <meta property="og:title" content="Validia - Securing Enterprise Digital Identity" />
        <meta property="og:description" content="Protect against deepfakes and AI impersonation with Validia" />
        <meta property="og:image" content="https://validia.ai/og-image.jpg" />
        <meta property="og:url" content="https://validia.ai" />
        <link rel="canonical" href="https://validia.ai" />
        <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
        <link rel="apple-touch-icon" href="%PUBLIC_URL%/validia_icon.png" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@validia_" />
        <meta name="twitter:title" content="Validia - Securing Enterprise Digital Identity" />
        <meta name="twitter:description" content="Protect your enterprise from deepfake threats with Validia's advanced AI solutions. Secure your digital identity today." />
        <meta name="twitter:image" content="https://validia.ai/twitter-image.png" />

      </Helmet>

      <BelowHeaderBanner></BelowHeaderBanner>

      <main>


        <section className="hero">
          <video autoPlay muted loop playsInline className="hero-background-video">
            <source src="/background-video.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="hero-overlay"></div>
          <div className="hero-content">
            <h1>{t('welcome')}</h1>
            <p>{t('subtitle')}</p>
            <Button variant="contained" disableElevation className="primary-button" onClick={() => window.location.href = 'https://calendly.com/d/494-3xs-7yt/identifai-30-minute-meeting'}>{t('bookDemoNow')}<ArrowForwardIcon style={{ paddingLeft: "10px" }} /></Button>
          </div>
          <div className="hero-bottom">
            Securing meetings on the following platforms {integrationComponent}
          </div>
        </section>

        <Integrations />

        <ScrollingStats />

        <section className="unified-platform">
          <h2>{t('unifiedPlatform')}</h2>
          <KnowYour />

          <DemoViewer />

          <InteractiveFlowchart />

        </section>



        <section id="testimonials" className="testimonial-showcase">
  {/* Add this new div right here */}

  {/* Keep all your existing content */}
  <div className="testimonial-content">
    <h2>{t('partnerTestimonials')}</h2>
    <div className="testimonial-selector">
      {Object.keys(testimonials).map((key) => (
        <div
          key={key}
          className={`selector-item ${activeTestimonial === key ? 'active' : ''}`}
          onClick={() => setActiveTestimonial(key)}
        >
          {testimonials[key].icon}
          <span>{testimonials[key].title}</span>
        </div>
      ))}
    </div>
    <div className="testimonial-display">
      <p className="testimonial-text">{testimonials[activeTestimonial].text}</p>
      <p className="testimonial-author">— {testimonials[activeTestimonial].author}</p>
    </div>
  </div>
</section>

        <CaseStudySection />

        <AboutUs />

        <FAQ />

        <section id="contact" className="contact-section">
          <h2>{t('getInTouch')}</h2>
          <p className="contact-intro">{t('contactIntro')}</p>
          <div className="contact-container">
            <form className="contact-form" action="https://formspree.io/f/xyzgjaee" method="POST">
              <div className="form-group">
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  required
                  aria-label={t('yourName')}
                />
                <label>{t('yourName')}</label>
              </div>
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  required
                  aria-label={t('yourEmail')}
                />
                <label>{t('yourEmail')}</label>
              </div>
              <div className="form-group">
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleInputChange}
                  required
                  aria-label={t('yourMessage')}
                ></textarea>
                <label>{t('yourMessage')}</label>
              </div>
              <button type="submit" className="submit-button">
                {t('sendMessage')} <FaArrowRight />
              </button>
            </form>
            <div className="contact-info">
              <h3>{t('contactInformation')}</h3>
              <p>{t('contactDescription')}</p>
              <ul>
                <li><FaEnvelope /> <a href="mailto:justin@validia.ai">justin@validia.ai</a></li>
                <li><FaPhone /> <a href="tel:+15402870222">+1 (540) 287-0222</a></li>
              </ul>
            </div>
          </div>
        </section>
      </main>

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "Validia",
          "url": "https://validia.ai",
          "logo": "https://validia.ai/validia_icon.png",
          "description": "Validia protects against deepfakes and AI impersonation, securing enterprise digital identity in the age of adversarial AI.",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+15402870222",
            "contactType": "customer support",
            "email": "justin@validia.ai"
          },
          "sameAs": [
            "https://www.linkedin.com/company/validia-ai",
            "https://x.com/validia_"
          ]
        })}
      </script>
    </div>
  );
}

export default HomePage;

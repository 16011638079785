import React from "react";
import './SocialShare.css';

const SocialShare = ({ url, title }) => {
    const pageUrl = window.location.href;
    const pageTitle = document.title;
    const shareUrl = url || pageUrl;
    const shareTitle = title || pageTitle;
    const encodedUrl = encodeURIComponent(shareUrl);
    const encodedTitle = encodeURIComponent(shareTitle);

    // Sharing URLs
    const twitterShare = `https://twitter.com/intent/tweet?url=${encodedUrl}&text=${encodedTitle}`;
    const linkedInShare = `https://www.linkedin.com/shareArticle?url=${encodedUrl}&title=${encodedTitle}`;
    const facebookShare = `https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`;

    return (
        <div className="social-share-container">
            <h3 classname="heading">Share this article:</h3>
            <div className="button-container">
                <a className="button" href={twitterShare} target="_blank" rel="noopener noreferrer" style={{backgroundColor: "#1DA1F2" }}>X/Twitter</a>
                <a className="button" href={linkedInShare} target="_blank" rel="noopener noreferrer" style={{backgroundColor: "#0077b5" }}>LinkedIn</a>
                <a className="button" href={facebookShare} target="_blank" rel="noopener noreferrer" style={{backgroundColor: "#3b5998" }}>Facebook</a>
            </div>
        </div>
    );
};

export default SocialShare;

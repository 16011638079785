import './BlogPost.css';

import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import client from '../config/contentful';
import { Helmet } from 'react-helmet';
import SocialShare from './SocialShare';

const BlogPost = () => {
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { slug } = useParams();
  const navigate = useNavigate();

  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { title, description, file } = node.data.target.fields;
        const imageUrl = file.url;
        return (
          <img
            src={imageUrl}
            alt={description || title || 'Blog image'}
            className="post-content-image"
          />
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => <p>{children}</p>,
      [BLOCKS.HEADING_1]: (node, children) => <h1 className="text-3xl font-bold mt-8 mb-4">{children}</h1>,
      [BLOCKS.HEADING_2]: (node, children) => <h2 className="text-2xl font-bold mt-8 mb-4">{children}</h2>,
      [BLOCKS.HEADING_3]: (node, children) => <h3 className="text-xl font-bold mt-6 mb-3">{children}</h3>,
      [BLOCKS.UL_LIST]: (node, children) => <ul>{children}</ul>,
      [BLOCKS.OL_LIST]: (node, children) => <ol>{children}</ol>,
      [BLOCKS.LIST_ITEM]: (node, children) => <li>{children}</li>,
      [BLOCKS.QUOTE]: (node, children) => <blockquote>{children}</blockquote>,
      [BLOCKS.HR]: () => <hr className="my-8 border-t border-gray-300" />,
      [INLINES.HYPERLINK]: (node, children) => (
        <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      ),
    },
    renderMark: {
      [MARKS.BOLD]: text => <strong>{text}</strong>,
      [MARKS.ITALIC]: text => <em>{text}</em>,
      [MARKS.CODE]: text => <code className="bg-gray-100 px-2 py-1 rounded">{text}</code>,
    },
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        // For testing loading state
        await new Promise(resolve => setTimeout(resolve, 250));

        const response = await client.getEntries({
          content_type: 'blogPost',
          'fields.slug': slug,
          include: 10
        });

        if (!response.items.length) {
          navigate('/blog');
          return;
        }

        const postData = response.items[0];
        const postDate = new Date(postData.fields.date ? postData.fields.date : postData.sys.createdAt);
        setPost({
          title: postData.fields.title,
          date: postDate.toLocaleDateString(),
          readTime: postData.fields.readTime || '5 min read',
          image: postData.fields.coverImage?.fields?.file?.url,
          content: postData.fields.content
        });

        setTimeout(() => setLoading(false), 300);
      } catch (err) {
        setError('Failed to fetch blog post');
        console.error(err);
        setLoading(false);
      }
    };

    fetchPost();
  }, [slug, navigate]);

  return (
    <>
      {post && (
        <Helmet>
          <title>{post.title}</title>
          <meta name="description" content={post.title}/>
          <meta property="og:title" content={post.title} />
          <meta property="og:description" content={post.title} />
          <meta property="og:url" content={`https://validia.ai/blog/${slug}`} />
          <meta property="og:image" content={post.image} />
          <meta name="twitter:title" content={post.title} />
          <meta name="twitter:description" content={post.title} />
          <meta name="twitter:url" content={`https://validia.ai/blog/${slug}`} />
          <meta name="twitter:image" content={post.image} />
          <link rel="canonical" href={`https://validia.ai/blog/${slug}`} />
          <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
          <link rel="apple-touch-icon" href={post.image} />
        </Helmet>
      )}

      <div className="page-container">
        {loading && (
          <div className="loading-container">
            <div className="loading-spinner" />
            <div className="loading-text">Loading Article</div>
          </div>
        )}

        <div className={`blog-post-container ${!loading ? 'loaded' : ''}`}>
          <Link to="/blog" className="back-link">← Back to Blog</Link>

          {error ? (
            <div>{error}</div>
          ) : post && (
            <div>
                <article>
                  <header className="post-header">
                    <h1 className="post-title">{post.title}</h1>
                    <div className="post-meta">
                      {post.date} • {post.readTime}
                    </div>
                  </header>

                  {post.image && (
                    <img
                      src={post.image}
                      alt={post.title}
                      className="post-image"
                    />
                  )}

                  <div className="post-content">
                    {documentToReactComponents(post.content, options)}
                  </div>
                </article>
                <SocialShare/>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogPost;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "welcome": "Outsmart AI Threats. Secure Your Workforce.",
      "subtitle": "Empower your enterprise with Validia's real-time identity authentication, deepfake disruption, and comprehensive protection against AI-powered attacks.",
      "product": "Product",
      "company": "Company",
      "aboutUs": "About Us",
      "customers": "Customers",
      "blog": "Blog",
      "caseStudies": "Case Studies",
      "contact": "Contact",
      "signin": "Sign in",
      "getDemo": "Get a demo",
      "metaDescription": "Validia protects against deepfakes and AI impersonation, securing enterprise digital identity in the age of adversarial AI.",
      "ogTitle": "Validia - Securing Enterprise Digital Identity",
      "ogDescription": "Protect against deepfakes and AI impersonation with Validia",
      "videoNotSupported": "Your browser does not support the video tag.",
      "comprehensiveProtection": "Comprehensive Protection",
      "proactiveDefense": "Proactive Defense",
      "proactiveDefenseDescription": "Prevent deepfake creation before it starts, safeguarding your digital identity.",
      "liveVerification": "Live Verification",
      "liveVerificationDescription": "Ensure authentic interactions in video conferences and digital communications.",
      "adaptiveSecurity": "Adaptive Security",
      "adaptiveSecurityDescription": "Stay protected against evolving AI threats with our continuously updated solutions.",
      "brandIntegrity": "Brand Integrity",
      "brandIntegrityDescription": "Maintain trust and credibility by securing your online presence against impersonation.",
      "unifiedPlatform": "A Unified Platform for Digital Identity Protection",
      "kyeTitle": "Know Your Employee",
      "kyeTagline": "Real-time identity verification for secure video calls",
      "kyeDescription": "Prevent unauthorized access and mitigate deepfake-enabled social engineering risks with our advanced AI-powered solution.",
      "kyeDetail1": "Continuous identity verification throughout video calls",
      "kyeDetail2": "AI-powered facial recognition and behavior analysis",
      "kyeDetail3": "Integration with major video conferencing platforms",
      "kyeDetail4": "Customizable alert systems for security teams",
      "kyeSlide1": "Add meeting in our Validia portal",
      "kyeSlide2": "Join your call!",
      "kyeSlide3": "Validia seamlessly joins the call",
      "kyeSlide4": "Live verification of users within 10 seconds",
      "jamTitle": "Jam",
      "jamTagline": "Pre-Attack Defense Against Deepfake Creation",
      "jamDescription": "Prevent the creation of Deepfake content before you even release your content",
      "jamDetail1": "Disrupts AI models used for deepfakes.",
      "jamDetail2": "Creates an invisible firewall around digital assets.",
      "jamDetail3": "Acts as a kill switch against unauthorized use of your digital identity",
      "jamDetail4": "Preserves your integrity as AI technology advances.",
      "jamDetail5": "Prevents the creation of convincing deepfakes using your data",
      "jamSlide1": "Invisible protective layer added to image",
      "jamSlide2": "A deepfake created with the original image",
      "jamSlide3": "A deepfake created with Validia's Jam",
      "stat1": "of online content could be synthetically generated by 2026",
      "stat2": "of employees are willing to act on AI-impersonated instructions",
      "stat3": "of employees are confident in identifying deepfake fraud",
      "stat4": "Projected deepfake losses by 2027, growing 32% annually",
      "stat5": "of C-Suite executives experienced deepfake incidents in past year",
      "stat6": "of C-Suite executives expect increased deepfake attacks on financial data",
      "stat7": "of C-Suite executives are confident in managing deepfake threats",
      "stat8": "of large enterprises expect increased deepfake attacks this year",
      "partnerTestimonials": "What Our Partners Say",
      "governmentTitle": "Government",
      "governmentTestimonial": "Validia offers a vital solution for protecting communications.",
      "governmentAuthor": "CISO, Foreign Embassy",
      "corporateSecurityTitle": "Corporate Security",
      "corporateSecurityTestimonial": "The need for a KYE/KYC solution like Validia is imperative for businesses in 2024 and beyond.",
      "corporateSecurityAuthor": "CRO, Data removal company & enterprises & UHNW",
      "internetMonitoringTitle": "Internet Monitoring",
      "internetMonitoringTestimonial": "The analysis from Validia helps us in our efforts to address misinformation-at-scale.",
      "internetMonitoringAuthor": "Head, MCA Deepfakes Analysis Unit",
      "meetOurTeam": "Meet Our Team",
      "getInTouch": "Get in Touch",
      "contactIntro": "Have questions? We'd love to hear from you.",
      "yourName": "Your Name",
      "yourEmail": "Your Email",
      "yourMessage": "Your Message",
      "sendMessage": "Send Message",
      "contactInformation": "Contact Information",
      "contactDescription": "We're here to help and answer any question you might have. We look forward to hearing from you.",
      "copyright": "© {{year}} Validia. All rights reserved.",
      "scheduleDemoTitle": "Experience a Demo as Your Digital Doppelgänger",
      "scheduleDemoSubtitle": "See the Power of Validia's KYE Verification by Meeting Your AI Twin",
      "scheduleDemoFormTitle": "Book Here!",
      "formName": "Full Name",
      "formEmail": "Business Email",
      "formCompany": "Company Name",
      "formImage": "Selfie Image",
      "formSubmit": "Submit Form",
      "testimonials": "Testimonials",
      "faqs": "FAQs",
      "talkToSomeoneReal": "Talk to Someone Real",
      "whyShouldYouProtect": "Using Your Eyes and Ears Has Become Insufficient",
      "aboutUsParagraph": "At Validia, we are a passionate and forward-thinking team dedicated to safeguarding digital integrity. As a startup at the forefront of deep fake prevention and protection solutions, we combine cutting-edge artificial intelligence with innovative security practices to help businesses, individuals, and platforms defend against the growing threat of AI-generated content manipulation. Our diverse team brings together expertise from AI research, cybersecurity, and digital forensics, all united by a shared commitment to building a safer and more trustworthy digital world. We don’t just fight deep fakes—we build the future of secure, authentic digital interactions.",
      "ourProducts": "Our Products",
      "bookDemoNow": "Book a Demo Now",
      "bookDemo": "Demo",
      "newsletter": "Newsletter",
      "stayUpToDateOnDeepfakes": "Stay Up To Date on Deepfakes"
    }
  },
  ja: {
    translation: {
      "welcome": "AI の脅威を出し抜きます。従業員を保護します",
      "subtitle": "リアルタイムの本人確認、ディープフェイクによる破壊、AI を利用した攻撃に対する包括的な保護により、企業を強化します",
      "product": "製品",
      "caseStudies": "ケーススタディ",
      "company": "会社",
      "aboutUs": "会社概要",
      "customers": "顧客",
      "blog": "ブログ",
      "contact": "お問い合わせ",
      "signin": "サインイン",
      "getDemo": "デモを見る",
      "metaDescription": "Validiaは、敵対的AIの時代にディープフェイクとAIなりすましから保護し、エンタープライズデジタルIDを確保します。",
      "ogTitle": "Validia - エンタープライズデジタルIDの保護",
      "ogDescription": "ValidiaでディープフェイクとAIなりすましから保護",
      "videoNotSupported": "お使いのブラウザは動画タグをサポートしていません。",
      "comprehensiveProtection": "包括的な保護",
      "proactiveDefense": "先制防御",
      "proactiveDefenseDescription": "ディープフェイクの作成を事前に防止し、デジタルIDを保護します。",
      "liveVerification": "リアルタイム検証",
      "liveVerificationDescription": "ビデオ会議やデジタルコミュニケーションでの本物の交流を確保します。",
      "adaptiveSecurity": "適応型セキュリティ",
      "adaptiveSecurityDescription": "常に更新されるソリューションで、進化するAIの脅威から保護します。",
      "brandIntegrity": "ブランド整合性",
      "brandIntegrityDescription": "オンラインでの存在を保護し、信頼性と信用を維持します。",
      "unifiedPlatform": "デジタルID保護のための統合プラットフォーム",
      "kyeTitle": "従業員確認",
      "kyeTagline": "安全なビデオ通話のためのリアルタイムID検証",
      "kyeDescription": "高度なAI搭載ソリューションで、不正アクセスを防止し、ディープフェイクを利用したソーシャルエンジニアリングのリスクを軽減します。",
      "kyeDetail1": "ビデオ通話中の継続的なID検証",
      "kyeDetail2": "AI搭載の顔認識と行動分析",
      "kyeDetail3": "主要なビデオ会議プラットフォームとの統合",
      "kyeDetail4": "セキュリティチーム向けのカスタマイズ可能なアラートシステム",
      "kyeSlide1": "Validiaポータルでミーティングを追加",
      "kyeSlide2": "通話に参加！",
      "kyeSlide3": "Validiaがシームレスに通話に参加",
      "kyeSlide4": "10秒以内にユーザーのリアルタイム検証",
      "jamTitle": "ジャム",
      "jamTagline": "ディープフェイク作成に対する事前防御",
      "jamDescription": "コンテンツを公開する前にディープフェイクコンテンツの作成を防止",
      "jamDetail1": "ディープフェイクに使用されるAIモデルを妨害します。",
      "jamDetail2": "デジタル資産周りに見えないファイアウォールを作成します。",
      "jamDetail3": "デジタルIDの不正使用に対するキルスイッチとして機能します。",
      "jamDetail4": "AI技術の進歩に伴い、あなたの整合性を保護します。",
      "jamDetail5": "あなたのデータを使用した説得力のあるディープフェイクの作成を防止します。",
      "jamSlide1": "画像に見えない保護層を追加",
      "jamSlide2": "オリジナル画像で作成されたディープフェイク",
      "jamSlide3": "ValidiaのJamで作成されたディープフェイク",
      "stat1": "2026年までにオンラインコンテンツの90%が合成的に生成される可能性があります",
      "stat2": "従業員の80%がAIによる偽装指示に従う意思があります",
      "stat3": "従業員の36%がそのような詐欺を識別する自信があります",
      "stat4": "2027年までのディープフェイクによる損失予測は年間32%成長で400億ドルです",
      "stat5": "過去 1 年間にディープフェイク事件を経験した経営幹部の割合",
      "stat6": "経営幹部の割合は、財務データに対するディープフェイク攻撃の増加を予想しています",
      "stat7": "の経営幹部がディープフェイクの脅威の管理に自信を持っています",
      "stat8": "の大企業の割合は、今年ディープフェイク攻撃が増加すると予想しています",
      "partnerTestimonials": "お客様の声",
      "governmentTitle": "政府",
      "governmentTestimonial": "Validiaは通信保護のための重要なソリューションを提供しています。",
      "governmentAuthor": "外国大使館 CISO",
      "corporateSecurityTitle": "企業セキュリティ",
      "corporateSecurityTestimonial": "ValidiaのようなKYE/KYCソリューションの必要性は、2024年以降のビジネスにとって不可欠です。",
      "corporateSecurityAuthor": "データ削除会社 & 企業 & UHNW CRO",
      "internetMonitoringTitle": "インターネットモニタリング",
      "internetMonitoringTestimonial": "Validiaの分析は、大規模な誤情報に対処する我々の取り組みに役立っています。",
      "internetMonitoringAuthor": "MCAディープフェイク分析ユニット責任者",
      "meetOurTeam": "チーム紹介",
      "getInTouch": "お問い合わせ",
      "contactIntro": "ご質問がありますか？お気軽にお問い合わせください。",
      "yourName": "お名前",
      "yourEmail": "メールアドレス",
      "yourMessage": "メッセージ",
      "sendMessage": "送信",
      "contactInformation": "連絡先情報",
      "contactDescription": "お客様のご質問にお答えし、サポートさせていただきます。お問い合わせをお待ちしております。",
      "copyright": "© {{year}} Validia. All rights reserved.",
      "scheduleDemoTitle": "デジタルドッペルゲンガーとしてデモを体験",
      "scheduleDemoSubtitle": "あなたのAIツインに会って、ValidiaのKYE認証の力を体験してください",
      "scheduleDemoFormTitle": "ここで予約！",
      "formName": "氏名",
      "formEmail": "ビジネスメールアドレス",
      "formCompany": "会社名",
      "formImage": "自撮り画像をアップロード",
      "formSubmit": "デモをスケジュール",
      "testimonials": "証言",
      "faqs": "よくある質問",
      "talkToSomeoneReal": "本当の誰かと話す",
      "whyShouldYouProtect": "なぜ自分を守る必要があるのですか?",
      "aboutUsParagraph": "Validia は、デジタル整合性の保護に専念する情熱的で先進的なチームです。ディープフェイク防止および保護ソリューションの最前線に立つスタートアップとして、当社は最先端の人工知能と革新的なセキュリティ実践を組み合わせて、企業、個人、プラットフォームが AI によって生成されたコンテンツ操作の増大する脅威から防御できるよう支援します。当社の多様なチームは、AI 研究、サイバーセキュリティ、デジタル フォレンジックの専門知識を結集し、より安全で信頼できるデジタル世界を構築するという共通の取り組みによって団結しています。私たちはディープフェイクと戦うだけではなく、安全で本物のデジタル インタラクションの未来を構築します。",
      "ourProducts": "当社の製品",
      "bookDemoNow": "今すぐデモを予約する",
      "bookDemo": "デモを予約する",
      "newsletter": "ニュースレター",
      "stayUpToDateOnDeepfakes": "ディープフェイクに関する最新情報を入手する"
    }
  },
  es: {
    translation: {
      "welcome": "Supere las amenazas de IA. Asegure su fuerza laboral.",
      "subtitle": "Fortalezca a su empresa con verificación de identidad en tiempo real, disrupción de deepfake y protección integral contra ataques impulsados ​​por IA",
      "product": "Producto",
      "company": "Empresa",
      "aboutUs": "Sobre Nosotros",
      "customers": "Clientes",
      "blog": "Blog",
      "caseStudies": "Estudios de Caso",
      "contact": "Contacto",
      "signin": "Iniciar sesión",
      "getDemo": "Obtener una demo",
      "metaDescription": "Validia protege contra deepfakes e imposición de IA, asegurando la identidad digital empresarial en la era de la IA adversaria.",
      "ogTitle": "Validia - Protegiendo la Identidad Digital Empresarial",
      "ogDescription": "Protéjase contra deepfakes e imposición de IA con Validia",
      "videoNotSupported": "Su navegador no soporta la etiqueta de video.",
      "comprehensiveProtection": "Protección Integral",
      "proactiveDefense": "Defensa Proactiva",
      "proactiveDefenseDescription": "Evite la creación de deepfakes antes de que comiencen, salvaguardando su identidad digital.",
      "liveVerification": "Verificación en Vivo",
      "liveVerificationDescription": "Asegure interacciones auténticas en videoconferencias y comunicaciones digitales.",
      "adaptiveSecurity": "Seguridad Adaptativa",
      "adaptiveSecurityDescription": "Manténgase protegido contra amenazas de IA en evolución con nuestras soluciones continuamente actualizadas.",
      "brandIntegrity": "Integridad de Marca",
      "brandIntegrityDescription": "Mantenga la confianza y credibilidad asegurando su presencia en línea contra la suplantación.",
      "unifiedPlatform": "Una Plataforma Unificada para la Protección de Identidad Digital",
      "kyeTitle": "Conozca a Su Empleado",
      "kyeTagline": "Verificación de identidad en tiempo real para llamadas de video seguras",
      "kyeDescription": "Evite el acceso no autorizado y mitigue los riesgos de ingeniería social habilitados por deepfakes con nuestra solución avanzada impulsada por IA.",
      "kyeDetail1": "Verificación de identidad continua durante las videollamadas",
      "kyeDetail2": "Reconocimiento facial y análisis de comportamiento impulsado por IA",
      "kyeDetail3": "Integración con las principales plataformas de videoconferencia",
      "kyeDetail4": "Sistemas de alerta personalizables para equipos de seguridad",
      "kyeSlide1": "Agregue reuniones en nuestro portal Validia",
      "kyeSlide2": "¡Únase a su llamada!",
      "kyeSlide3": "Validia se une a la llamada sin problemas",
      "kyeSlide4": "Verificación en vivo de usuarios en 10 segundos",
      "jamTitle": "Jam",
      "jamTagline": "Defensa Pre-Ataque Contra la Creación de Deepfakes",
      "jamDescription": "Evite la creación de contenido Deepfake antes incluso de que libere su contenido",
      "jamDetail1": "Interrumpe los modelos de IA utilizados para deepfakes.",
      "jamDetail2": "Crea un firewall invisible alrededor de los activos digitales.",
      "jamDetail3": "Actúa como un interruptor de emergencia contra el uso no autorizado de su identidad digital",
      "jamDetail4": "Preserva su integridad a medida que avanza la tecnología de IA.",
      "jamDetail5": "Evita la creación de deepfakes convincentes utilizando sus datos",
      "jamSlide1": "Capa Protectora Invisible Añadida a la Imagen",
      "jamSlide2": "Un deepfake creado con la imagen original",
      "jamSlide3": "Un deepfake creado con Jam de Validia",
      "stat1": "del contenido en línea podría ser generado sintéticamente para 2026",
      "stat2": "de los empleados están dispuestos a actuar según instrucciones suplantadas por IA",
      "stat3": "de los empleados confían en identificar tal fraude",
      "stat4": "Pérdidas proyectadas por deepfakes para 2027, creciendo un 32% anualmente",
      "stat5": "de los ejecutivos de C-Suite experimentaron incidentes deepfake el año pasado",
      "stat6": "de los ejecutivos de C-Suite esperan un aumento de los ataques deepfake a los datos financieros",
      "stat7": "de los ejecutivos de C-Suite confían en la gestión de amenazas deepfake",
      "stat8": "de las grandes empresas esperan un aumento de los ataques deepfake este año",
      "partnerTestimonials": "Lo que Dicen Nuestros Socios",
      "governmentTitle": "Gobierno",
      "governmentTestimonial": "Validia ofrece una solución vital para proteger las comunicaciones.",
      "governmentAuthor": "CISO, Embajada Extranjera",
      "corporateSecurityTitle": "Seguridad Corporativa",
      "corporateSecurityTestimonial": "La necesidad de una solución KYE/KYC como Validia es imperativa para las empresas en 2024 y más allá.",
      "corporateSecurityAuthor": "CRO, Empresa de eliminación de datos y empresas y UHNW",
      "internetMonitoringTitle": "Monitoreo de Internet",
      "internetMonitoringTestimonial": "El análisis de Validia nos ayuda en nuestros esfuerzos para abordar la desinformación a gran escala.",
      "internetMonitoringAuthor": "Jefe, Unidad de Análisis de Deepfakes MCA",
      "meetOurTeam": "Conozca a Nuestro Equipo",
      "getInTouch": "Póngase en Contacto",
      "contactIntro": "¿Tiene preguntas? Nos encantaría saber de usted.",
      "yourName": "Su Nombre",
      "yourEmail": "Su Correo Electrónico",
      "yourMessage": "Su Mensaje",
      "sendMessage": "Enviar Mensaje",
      "contactInformation": "Información de Contacto",
      "contactDescription": "Estamos aquí para ayudar y responder cualquier pregunta que pueda tener. Esperamos saber de usted.",
      "copyright": "© {{year}} Validia. Todos los derechos reservados.",
      "scheduleDemoTitle": "Experimente una demostración como su Doppelgänger digital",
      "scheduleDemoSubtitle": "Vea el poder de la verificación KYE de Validia conociendo a su gemelo AI",
      "scheduleDemoFormTitle": "¡Reserve aquí!",
      "formName": "Nombre completo",
      "formEmail": "Correo electrónico de trabajo",
      "formCompany": "Nombre de la empresa",
      "formImage": "Subir imagen de selfie",
      "formSubmit": "Programar demostración",
      "testimonials": "Testimonios",
      "faqs": "Preguntas Frecuentes",
      "talkToSomeoneReal": "Hablar con Alguien Real",
      "whyShouldYouProtect": "¿Por qué deberías protegerte?",
      "aboutUsParagraph": "En Validia, somos un equipo apasionado y con visión de futuro dedicado a salvaguardar la integridad digital. Como startup a la vanguardia de las soluciones de protección y prevención de deepfake, combinamos inteligencia artificial de vanguardia con prácticas de seguridad innovadoras para ayudar a empresas, individuos y plataformas a defenderse contra la creciente amenaza de la manipulación de contenido generado por IA. Nuestro equipo diverso reúne experiencia en investigación de inteligencia artificial, ciberseguridad y análisis forense digital, todos unidos por un compromiso compartido de construir un mundo digital más seguro y confiable. No solo luchamos contra las falsificaciones profundas: construimos el futuro de interacciones digitales auténticas y seguras.",
      "ourProducts": "Nuestros Productos",
      "bookDemoNow": "Reserve una demostración ahora",
      "bookDemo": "Reserve una demostración",
      "newsletter": "Hoja informativa",
      "stayUpToDateOnDeepfakes": "Manténgase actualizado sobre deepfakes"
    }
  },
  zh: {
    translation: {
      "welcome": "智胜人工智能威胁。确保您的劳动力安全。",
      "subtitle": "为您的企业提供实时身份验证、深度伪造破坏和针对人工智能攻击的全面防护",
      "product": "产品",
      "company": "公司",
      "aboutUs": "关于公司",
      "customers": "客户",
      "blog": "博客",
      "caseStudies": "案例研究",
      "contact": "联系我们",
      "signin": "登录",
      "getDemo": "获取演示",
      "metaDescription": "Validia保护企业免受deepfake和AI冒充的威胁，在对抗性人工智能时代保护企业数字身份。",
      "ogTitle": "Validia - 保护企业数字身份",
      "ogDescription": "使用Validia防御Deepfake和AI冒充",
      "videoNotSupported": "您的浏览器不支持视频标签。",
      "comprehensiveProtection": "全面保护",
      "proactiveDefense": "主动防御",
      "proactiveDefenseDescription": "在deepfake创建之前就阻止它，保护您的数字身份。",
      "liveVerification": "实时验证",
      "liveVerificationDescription": "确保视频会议和数字通信中的真实互动。",
      "adaptiveSecurity": "自适应安全",
      "adaptiveSecurityDescription": "通过我们持续更新的解决方案，保护您免受不断演变的AI威胁。",
      "brandIntegrity": "品牌完整性",
      "brandIntegrityDescription": "通过保护您的在线存在免受冒充，保持信任和可信度。",
      "unifiedPlatform": "数字身份保护统一平台",
      "kyeTitle": "了解您的员工",
      "kyeTagline": "安全视频通话的实时身份验证",
      "kyeDescription": "通过我们先进的AI驱动解决方案，防止未经授权的访问并降低由deepfake引起的社会工程风险。",
      "kyeDetail1": "视频通话全程持续身份验证",
      "kyeDetail2": "AI驱动的面部识别和行为分析",
      "kyeDetail3": "与主要视频会议平台集成",
      "kyeDetail4": "为安全团队提供可定制的警报系统",
      "kyeSlide1": "在我们的Validia门户中添加会议",
      "kyeSlide2": "加入您的通话！",
      "kyeSlide3": "Validia无缝加入通话",
      "kyeSlide4": "10秒内完成用户实时验证",
      "jamTitle": "Jam",
      "jamTagline": "Deepfake创建的预防性防御",
      "jamDescription": "在您发布内容之前就防止Deepfake内容的创建",
      "jamDetail1": "破坏用于deepfake的AI模型。",
      "jamDetail2": "在数字资产周围创建一个无形的防火墙。",
      "jamDetail3": "作为防止未经授权使用您的数字身份的终止开关",
      "jamDetail4": "随着AI技术的进步保护您的完整性。",
      "jamDetail5": "防止使用您的数据创建令人信服的deepfakes",
      "jamSlide1": "为图像添加无形保护层",
      "jamSlide2": "使用原始图像创建的deepfake",
      "jamSlide3": "使用Validia的Jam创建的deepfake",
      "stat1": "到2026年，在线内容可能由合成生成",
      "stat2": "的员工愿意按照AI冒充的指示行事",
      "stat3": "的员工有信心识别此类欺诈",
      "stat4": "到2027年，deepfake造成的损失预计每年增长32%",
      "stat5": "的高管在过去一年中经历过深度造假事件",
      "stat6": "的高管预计金融数据的深度造假攻击将会增加",
      "stat7": "的首席高管对管理深度伪造威胁充满信心",
      "stat8": "的大型企业预计今年深度伪造攻击将会增加",
      "partnerTestimonials": "客户评价",
      "governmentTitle": "政府",
      "governmentTestimonial": "Validia为保护通信提供了至关重要的解决方案。",
      "governmentAuthor": "外国大使馆首席信息安全官",
      "corporateSecurityTitle": "企业安全",
      "corporateSecurityTestimonial": "像Validia这样的KYE/KYC解决方案对2024年及以后的企业来说是必不可少的。",
      "corporateSecurityAuthor": "数据删除公司、企业和超高净值客户首席风险官",
      "internetMonitoringTitle": "互联网监控",
      "internetMonitoringTestimonial": "Validia的分析帮助我们努力解决大规模虚假信息问题。",
      "internetMonitoringAuthor": "MCA Deepfakes分析部门负责人",
      "meetOurTeam": "认识我们的团队",
      "getInTouch": "联系我们",
      "contactIntro": "有问题吗？我们很乐意听取您的意见。",
      "yourName": "您的姓名",
      "yourEmail": "您的电子邮箱",
      "yourMessage": "您的留言",
      "sendMessage": "发送消息",
      "contactInformation": "联系信息",
      "contactDescription": "我们在这里为您提供帮助并回答您可能有的任何问题。我们期待收到您的来信。",
      "copyright": "© {{year}} Validia。保留所有权利。",
      "scheduleDemoTitle": "体验成为您的数字分身",
      "scheduleDemoSubtitle": "通过与您的AI分身会面，体验Validia的KYE验证力量",
      "scheduleDemoFormTitle": "在此预约！",
      "formName": "全名",
      "formEmail": "商务邮箱",
      "formCompany": "公司名称",
      "formImage": "上传自拍照片",
      "formSubmit": "安排演示",
      "testimonials": "感言",
      "faqs": "常见问题",
      "talkToSomeoneReal": "与真实的人交谈",
      "whyShouldYouProtect": "你为什么要保护自己?",
      "aboutUsParagraph": "在 Validia，我们是一支充满热情和前瞻性思维的团队，致力于维护数字完整性。作为深度造假预防和保护解决方案前沿的初创公司，我们将尖端人工智能与创新安全实践相结合，帮助企业、个人和平台抵御人工智能生成的内容操纵日益增长的威胁。我们多元化的团队汇集了人工智能研究、网络安全和数字取证的专业知识，所有这些都致力于构建一个更安全、更值得信赖的数字世界。我们不仅仅打击深度造假，我们还构建安全、真实的数字交互的未来。",
      "ourProducts": "我们的产品",
      "bookDemoNow": "立即预订演示",
      "bookDemo": "预订演示",
      "newsletter": "通讯",
      "stayUpToDateOnDeepfakes": "了解 Deepfakes 的最新动态"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: 'en', // default language
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;

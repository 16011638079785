import React from 'react';

const NotFound = () => {
  return (
    <div style={{ minHeight: '60vh', paddingBottom: '100px', paddingTop: '120px' }} className="relative">
      <div className="w-full text-center px-4">
        <svg
          viewBox="0 0 200 200"
          style={{ width: '240px', height: '240px', margin: '0 auto', marginBottom: '16px' }}
        >
          {/* Fun robot face */}
          <g transform="translate(100 100)">
            {/* Robot head */}
            <rect
              x="-50"
              y="-60"
              width="100"
              height="120"
              rx="10"
              fill="#e0e0e0"
              stroke="#333"
              strokeWidth="4"
            />

            {/* Antenna */}
            <line
              x1="0"
              y1="-60"
              x2="0"
              y2="-80"
              stroke="#333"
              strokeWidth="4"
            />
            <circle
              cx="0"
              cy="-85"
              r="5"
              fill="#ff6b6b"
            />

            {/* Eyes */}
            <g className="eyes">
              <circle
                cx="-20"
                cy="-20"
                r="12"
                fill="#333"
              >
                <animate
                  attributeName="r"
                  values="12;8;12"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </circle>
              <circle
                cx="20"
                cy="-20"
                r="12"
                fill="#333"
              >
                <animate
                  attributeName="r"
                  values="12;8;12"
                  dur="2s"
                  repeatCount="indefinite"
                />
              </circle>
            </g>

            {/* Mouth */}
            <path
              d="M-20 20 Q0 40 20 20"
              fill="none"
              stroke="#333"
              strokeWidth="4"
              strokeLinecap="round"
            />

            {/* Error message */}
            <text
              x="0"
              y="60"
              textAnchor="middle"
              fill="#333"
              fontSize="16"
              fontFamily="monospace"
            >
              &lt;404/&gt;
            </text>
          </g>
        </svg>

        <h1 className="text-6xl font-bold text-gray-900 mb-4">Oops!</h1>
        <p className="text-xl text-gray-600 mb-4">Looks like this page took a wrong turn.</p>
        <p className="text-lg text-gray-500 mb-8">Don't worry, even robots get lost sometimes!</p>
        <a
          href="/"
          className="inline-block px-6 py-3 text-lg text-white bg-blue-600 rounded-lg hover:bg-blue-700 transition-colors duration-200"
        >
          Take Me Home
        </a>
      </div>
    </div>
  );
};

export default NotFound;

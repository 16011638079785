import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';

export default function TermsPolicy() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
      <>
          <Helmet>
              <title>Validia - Terms and Conditions</title>
              <meta name="description" content="Validia's terms and conditions." />
          </Helmet>
          <div className="w-full flex justify-center">
              <div
              className="w-3/4"
              name="termly-embed"
              data-id="970ce6ce-a4fb-4f2e-b3af-cac13b1b668f"
              data-type="iframe"
              ></div>
          </div>
      </>
  );
}

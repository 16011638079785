import './BlogList.css';

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import client from '../config/contentful';
import { Helmet } from 'react-helmet';

const BlogPage = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        // FORCING a delay to see the loading state
        await new Promise(resolve => setTimeout(resolve, 500));

        const response = await client.getEntries({
          content_type: 'blogPost',
          order: '-sys.createdAt'
        });

        const formattedPosts = response.items.map(item => ({
          id: item.sys.id,
          slug: item.fields.slug,
          title: item.fields.title,
          excerpt: item.fields.excerpt,
          date: (new Date(item.fields.date ? item.fields.date : item.sys.createdAt)).toLocaleDateString(),
          readTime: item.fields.readTime || '5 min read',
          image: item.fields.coverImage?.fields?.file?.url || 'https://picsum.photos/800/400' // TODO: use Validia logo as default
        }));

        setPosts(formattedPosts);
        // Small delay before removing loading state
        setTimeout(() => setLoading(false), 300);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const [featuredPost, ...otherPosts] = posts;

  return (
    <>

    <Helmet>
      <title>Validia Blog</title>
      <meta name="description" content="Insights and Outlooks on the Deepfake Space, and how Validia is tackling the identity problem with Know Your Employee"/>
      <meta property="og:title" content="Validia - Blogs" />
      <meta property="og:description" content="Insights and Outlooks on the Deepfake Space, and how Validia is tackling the identity problem with Know Your Employee" />
      <meta property="og:url" content="https://validia.ai/blog" />
      <meta property="og:image" content="%PUBLIC_URL%/validia-br.png" />
      <link rel="canonical" href="https://validia.ai/blog" />
      <link rel="sitemap" type="application/xml" href="/sitemap.xml" />
      <link rel="apple-touch-icon" href="%PUBLIC_URL%/validia-br.png" />


      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:site" content="@validia_" />
      <meta name="twitter:title" content="Validia Blog" />
      <meta name="twitter:description" content="Get the latest insights from Validia's team on identity impersonation, deepfakes, and how to protect yourself" />
      <meta name="twitter:image" content="https://validia.ai/twitter-image.png" />
    </Helmet>

      <div className="page-container">
        {loading && (
          <div className="loading-container">
            <div className="loading-spinner" />
            <div className="loading-text">Loading Articles</div>
          </div>
        )}

        <div className={`blog-container ${!loading ? 'loaded' : ''}`}>
          {featuredPost && (
            <div className="featured-post">
              <Link to={`/blog/${featuredPost.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                <div className="featured-post-inner">
                  <img src={featuredPost.image} alt={featuredPost.title} className="post-image" />
                  <div className="post-content">
                    <div className="post-meta">
                      {featuredPost.date} • {featuredPost.readTime}
                    </div>
                    <h2 className="post-title">{featuredPost.title}</h2>
                    <p className="post-excerpt">{featuredPost.excerpt}</p>
                    <span className="read-more">Read Article</span>
                  </div>
                </div>
              </Link>
            </div>
          )}

          {otherPosts.length > 0 && (
            <>
              <h2 className="section-title">More Articles</h2>
              <div className="posts-grid">
                {otherPosts.map(post => (
                  <article key={post.id} className="post-card">
                    <Link to={`/blog/${post.slug}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                      <img src={post.image} alt={post.title} className="post-image" />
                      <div className="post-content">
                        <div className="post-meta">
                          {post.date} • {post.readTime}
                        </div>
                        <h2 className="post-title">{post.title}</h2>
                        <p className="post-excerpt">{post.excerpt}</p>
                        <span className="read-more">Read more →</span>
                      </div>
                    </Link>
                  </article>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BlogPage;

import React from 'react';
import { CheckCircle, XCircle, User, Shield } from "lucide-react";
import './InteractiveFlowchart.css';

const InteractiveFlowchart = () => {
  const steps = [
    {
      Icon: User,
      title: "Impersonation Attempt",
      description: "Attacker calls organization, impersonating a vendor asking to change bank account info."
    },
    {
      Icon: Shield,
      title: "Authentication",
      description: "Organization leverages Validia to authenticate the vendor on the call."
    },
    {
      Icon: XCircle,
      title: "Authentication Failed",
      description: "Attacker fails authentication."
    },
    {
      Icon: CheckCircle,
      title: "Response",
      description: "Attacker is flagged and blocked, and a Validia report is generated."
    }
  ];

  return (
    <div className="arc-step-visualizer">
      <h2 className="arc-step-visualizer-title">Validia in Action</h2>
      <div className="arc-step-container">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className="arc-step">
              <div className="arc-step-icon">
                <step.Icon size={32} strokeWidth={2} />
              </div>
              <h3 className="arc-step-title">{step.title}</h3>
              <p className="arc-step-text">{step.description}</p>
            </div>

            {index < steps.length - 1 && (
              <div className="arc-step-connector"></div>
            )}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
};

export default InteractiveFlowchart;

import React, { useState } from "react";
import { Play, ArrowRight } from 'lucide-react';
import "./DemoViewer.css";

const DEMO_OPTIONS = [
  {
    id: 'secure-meeting',
    title: 'Secure Meetings',
    url: 'https://demo.arcade.software/bZ6ULIS4cK9yhc59QKNg?embed&embed_mobile=inline&embed_desktop=inline&show_copy_link=true',
    description: "Leverage Validia's advanced authentication technology to know and trust the people in your videos calls, whether they're employees, hiring candidates, or third-party vendors.",
    features: [
      'Seamlessly integrate with your calls',
      '5–10 second authentications',
      'Frictionless security'
    ]
  }
];

export function DemoViewer() {
  const [selectedDemo] = useState(DEMO_OPTIONS[0]);

  return (
    <div className="arc-demo-wrapper" id="demo">
      <div className="arc-demo-container">
        <div className="arc-demo-sidebar">
          <div className="arc-demo-content">
            <div className="arc-demo-badge">Click Through Demo</div>
            <h2 className="arc-demo-title">{selectedDemo.title}</h2>
            <p className="arc-demo-description">{selectedDemo.description}</p>
            <div className="arc-demo-features">
              <h3 className="arc-features-title">Key Capabilities</h3>
              <ul className="arc-features-list">
                {selectedDemo.features.map((feature, index) => (
                  <li key={index} className="arc-feature-item">
                    <Play size={16} className="arc-feature-icon" />
                    {feature}
                  </li>
                ))}
              </ul>
            </div>
            {/* Styled link that looks like the button */}
            <a
              href="https://calendly.com/d/494-3xs-7yt/identifai-30-minute-meeting"
              target="_blank"
              rel="noopener noreferrer"
              className="arc-demo-cta"
            >
              Request a Custom Demo
              <ArrowRight size={20} className="arc-cta-icon" />
            </a>
          </div>
        </div>
        <div className="arc-demo-main">
          <div className="arc-demo-iframe-wrapper">
            <iframe
              src={selectedDemo.url}
              title={selectedDemo.title}
              frameBorder="0"
              loading="lazy"
              allowFullScreen
              allow="clipboard-write"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DemoViewer;

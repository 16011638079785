import React from 'react';
import { FaLinkedin, FaTwitter, FaBluesky } from 'react-icons/fa6';

function TeamGrid({ members }) {

    const defaultImage = "validia_icon.png";

    return (
        <div className="team-grid">
            {members.map((member, index) => (
                <div key={index} className="team-card">
                    <img data-src={member.image ? member.image : defaultImage} alt={member.image ? member.name : "default profile image"} className="team-member-image" loading="lazy" />
                    <h3>{member.name}</h3>
                    <p>{member.position}</p>
                    <div className="social-links">
                        {member.linkedin && (
                            <a href={member.linkedin} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s LinkedIn`}>
                                <FaLinkedin className="social-icon" />
                            </a>
                        )}
                        {member.twitter && (
                            <a href={member.twitter} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s Twitter`}>
                                <FaTwitter className="social-icon" />
                            </a>
                        )}
                        {member.bluesky && (
                            <a href={member.bluesky} target="_blank" rel="noopener noreferrer" aria-label={`${member.name}'s Bluesky`}>
                                <FaBluesky className="social-icon" />
                            </a>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default TeamGrid;

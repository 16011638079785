import React, { useEffect } from "react";
import { Helmet } from 'react-helmet';

export default function Policy() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://app.termly.io/embed-policy.min.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

    return (
        <>
            <Helmet>
                <title>Validia - Privacy Policy</title>
                <meta name="description" content="Validia's privacy policy." />
            </Helmet>
            <div className="w-full flex justify-center">
                <div
                className="w-3/4"
                name="termly-embed"
                data-id="78de9a51-4bc1-47e2-95bf-465ff0cb2b39"
                data-type="iframe"
                ></div>
            </div>
        </>
    );
}
